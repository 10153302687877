// `localStorage` object may exist, but the API may not be available.
// Browsers offer settings that disable localStorage, like incognito mode.
// Checking will avoid throwing a bunch of errors on Sentry.
const storageAvailable = (type = "localStorage" as string) => {
  let storage
  try {
    // @ts-ignore-next-line
    storage = window[type]

    const x = "__storage_test__"
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return (
      e instanceof DOMException &&
      (e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    )
  }
}

export { storageAvailable }
