import { create, Mutate, StoreApi } from "zustand";
import { BonusCartItem, GiftCartState } from "./types";
import { Payload } from "@local/payload-client/src/types";
import { createJSONStorage, persist } from "zustand/middleware";
import { CartItem } from "@local/product-option-manager/types";
type StoreWithPersist = Mutate<StoreApi<GiftCartState>, [["zustand/persist", unknown]]>;

/**
 * This storage below allows the user cart to persist between tabs
 * This "rehydrates" the front-end whenever a storage event happens
 * Not my code, stole from here: https://github.com/pmndrs/zustand/blob/main/docs/integrations/persisting-store-data.md#how-can-i-rehydrate-on-storage-event
 * @param store This is the cart storage
 * @returns void
 */
export const withStorageDOMEvents = (store: StoreWithPersist) => {
  // Without this, on server side things will breaking bad
  if (typeof window === "undefined") return;
  const storageEventCallback = (e: StorageEvent) => {
    if (e.key === store.persist.getOptions().name && e.newValue) {
      store.persist.rehydrate();
    }
  };
  window.addEventListener("storage", storageEventCallback);
  return () => {
    window.removeEventListener("storage", storageEventCallback);
  };
};
export const useGiftCart = create<GiftCartState>()(persist((setState, getState) => ({
  bonusItems: [],
  bonusLevel: 0,
  setBonusLevel: (bonusLevel: number) => {
    setState({
      bonusLevel
    });
  },
  addBonusItems: ({
    items,
    level
  }: {
    items: Array<Payload.Product>;
    level: number;
  }) => {
    const cart = [...getState().bonusItems];
    items.map((item, i) => {
      const existingItem = cart.find(i => i.id === item.id && i.id === item.id);
      if (!existingItem) {
        cart.push({
          ...item,
          giftAccepted: false,
          // if there is more than 1 bonus per add, we need to shift the levelKey accordingly
          levelKey: items.length > 1 ? level - (items.length - 1 - i) : level
        });
      }
    });
    setState({
      bonusItems: cart
    });
  },
  removeBonusItems: (bonusLevel: number) => {
    setState({
      bonusItems: getState().bonusItems.filter((item: BonusCartItem) => item.levelKey <= bonusLevel)
    });
  },
  acceptGift: (levelKey: number, cartItem, image) => {
    const bonusItems = getState().bonusItems.map((item: BonusCartItem) => {
      if (item.levelKey === levelKey) {
        return {
          ...item,
          giftAccepted: true,
          cartItem: {
            ...cartItem,
            preOrder: null,
            price: 0
          },
          image
        };
      }
      return item;
    });
    setState({
      bonusItems
    });
  },
  removeBonusItemById: (bonusItemId: string) => {
    const bonusItems = getState().bonusItems.map((item: BonusCartItem) => {
      if (item.id === bonusItemId) {
        return {
          ...item,
          giftAccepted: false
        };
      }
      return item;
    });
    setState({
      bonusItems
    });
  },
  clearGiftCart: () => {
    setState({
      bonusItems: [],
      bonusLevel: 0
    });
  },
  // basically does kinda the same as above, exists only for the sake of consistency
  setGiftVariant: (levelKey: number, cartItem: CartItem, image) => {
    const bonusItems = getState().bonusItems.map((item: BonusCartItem) => {
      if (item.levelKey === levelKey) {
        return {
          ...item,
          cartItem,
          preOrder: null,
          image
        };
      }
      return item;
    });
    setState({
      bonusItems
    });
  },
  setGiftImage: (levelKey: number, image) => {
    const bonusItems = getState().bonusItems.map((item: BonusCartItem) => {
      if (item.levelKey === levelKey) {
        return {
          ...item,
          image
        };
      }
      return item;
    });
    setState({
      bonusItems
    });
  }
}), {
  name: "giftCart",
  storage: createJSONStorage(() => localStorage),
  version: 2
}));
withStorageDOMEvents(useGiftCart);