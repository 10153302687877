import Script from "next/script";
import type { ConversionProps } from "./types";
import { captureException } from "@sentry/nextjs";
import { isBrowser } from "@local/utils/src/isBrowser";
export const Twitter = () => {
  return <Script id="tw-script" data-sentry-element="Script" data-sentry-component="Twitter" data-sentry-source-file="twitter.tsx">
      {`
!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
twq('config','ojbee');
`}
    </Script>;
};
interface TwitterConversionProps extends ConversionProps {
  email?: string | null;
}
export const trackTwitter = (name: string, data?: any) => {
  if (isBrowser() && window.twq) {
    window.twq("event", name, data);
  }
};
export const twitterConvert = ({
  value,
  currency,
  email
}: TwitterConversionProps) => {
  try {
    if (window && window.twq) {
      trackTwitter("tw-ojbee-ojbef", {
        value: Math.round(value * 0.6).toFixed(2),
        currency,
        email_address: email ?? null
      });
    }
  } catch (e) {
    console.error("twitter not loaded");
    captureException("Could not send twitter conversion");
  }
};