"use client";

import type { Payload } from "@local/payload-client/src/types";
import { ContentBlockServer } from "../ContentBlock";
import ButtonServer, { ButtonIntent, ButtonStretch } from "../ui/button";
import { ArrowLeftIcon } from "../ui/icons";
import { barlowCondensed } from "@/utils/fonts";
import { useState } from "react";
import { BestSellersBigBlock, BestSellersSingleBlock } from "./BestSellersSingleBlock";
interface Props {
  block: Payload.BestSellersBlock;
}
export const BestSellersWithTitleBlock = ({
  block
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const headline = block.headline ?? "Shop our Best Sellers";
  const BestSellersMappedBlocks = ({
    skipFirst,
    vertical
  }: {
    skipFirst?: boolean;
    vertical?: boolean;
  }) => {
    return <div className="relative grid grid-cols-1 md:grid-cols-2 md:gap-x-9 md:gap-y-3 gap-8" data-sentry-component="BestSellersMappedBlocks" data-sentry-source-file="index.tsx">
        {block.product?.map((product, i) => {
        if (skipFirst && i === 0) return null;
        return <div key={`best-sellers-${product.id}`}>
              <BestSellersSingleBlock product={product} index={i + 1} isVisible={isVisible} vertical={vertical} />
            </div>;
      })}
        {!isVisible && <div className="md:hidden !z-0 absolute inset-0 bg-gradient-to-t from-0% from-white to-70% via-transparent pointer-events-none" />}
      </div>;
  };
  return <ContentBlockServer className="flex flex-col justify-center py-10 mt-6 lg:py-6 !max-w-6xl" data-sentry-element="ContentBlockServer" data-sentry-component="BestSellersWithTitleBlock" data-sentry-source-file="index.tsx">
      <div className="relative mx-6 xl:mx-0">
        <h2 className={`${barlowCondensed.className} flex md:relative text-5xl uppercase tracking-[-0.02em] leading-tight`}>
          <ArrowLeftIcon className="hidden xl:block mr-2 md:mr-0 md:absolute w-9 rotate-180 -left-12 top-3 text-[#E5E5E5]" data-sentry-element="ArrowLeftIcon" data-sentry-source-file="index.tsx" />
          <p>
            {headline.split(" ").slice(0, -2).join(" ")}
            <span className="ml-2 font-semibold">
              {headline.split(" ").slice(-2).join(" ")}
            </span>
          </p>
        </h2>

        {block?.product ? <>
            {/* desktop */}
            <div className="relative hidden grid-cols-1 gap-8 pt-6 pb-8 md:grid-cols-2 md:grid">
              <div>
                <BestSellersBigBlock product={block.product?.[0]} mobileImage={block.mobileImage} />
              </div>
              <div>
                <BestSellersMappedBlocks skipFirst vertical />
              </div>
            </div>

            {/* mobile */}
            <div className={`relative md:hidden pt-6 ${isVisible ? "pb-8" : "pb-16"}`}>
              <BestSellersMappedBlocks />

              {!isVisible && <ButtonServer content="View more" onClick={() => setIsVisible(!isVisible)} className="max-w-full absolute bottom-1 text-lg font-medium uppercase !py-6" intent={ButtonIntent.DarkCTA} stretch={ButtonStretch.fixed} />}
            </div>
          </> : null}
      </div>
    </ContentBlockServer>;
};
export default BestSellersWithTitleBlock;