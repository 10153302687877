type Service =
  | "cdp"
  | "coin"
  | "crown"
  | "dice"
  | "orca"
  | "passport"
  | "trolley"

type ServiceMap = Map<
  Service,
  {
    stg: string
    prd: string
  }
>

type STAGE_NAME = "prd" | "stg"

export const STAGE: STAGE_NAME = process.env.NEXT_PUBLIC_STAGE
  ? (process.env.NEXT_PUBLIC_STAGE as STAGE_NAME)
  : "prd"

export const ENDPOINTS: ServiceMap = new Map([
  [
    "cdp",
    {
      stg: "https://cdx.div.haus",
      prd: "https://cdp.div.haus",
    },
  ],
  [
    "coin",
    {
      stg: "https://coin.stg.div.haus",
      prd: "https://coin.div.haus",
    },
  ],
  [
    "crown",
    {
      stg: "https://crown.stg.div.haus",
      prd: "https://crown.div.haus",
    },
  ],
  [
    "dice",
    {
      stg: "https://dice.stg.div.haus",
      prd: "https://dice.div.haus",
    },
  ],
  [
    "orca",
    {
      stg: "https://orca.stg.div.haus",
      prd: "https://orca.div.haus",
    },
  ],
  [
    "passport",
    {
      stg: "https://pass.stg.div.haus",
      prd: "https://pass.div.haus",
    },
  ],
  [
    "trolley",
    {
      stg: "https://trolley.stg.div.haus",
      prd: "https://trolley.div.haus",
    },
  ],
])

export const getServiceEndpoint = (service: Service) =>
  ENDPOINTS.get(service)?.[STAGE]
