import { captureException } from "@sentry/nextjs";
import Script from "next/script";
interface BingProps {
  pixelId: string;
}
export const Bing = ({
  pixelId
}: BingProps) => {
  return <Script id="bing" dangerouslySetInnerHTML={{
    __html: `
     ;(function (w, d, t, r, u) {
       var f, n, i
       ;(w[u] = w[u] || []),
         (f = function () {
           var o = { ti: "${pixelId}", enableAutoSpaTracking: true }
           ;(o.q = w[u]), (w[u] = new UET(o)), w[u].push("pageLoad")
         }),
         (n = d.createElement(t)),
         (n.src = r),
         (n.async = 1),
         (n.onload = n.onreadystatechange =
           function () {
             var s = this.readyState
             ;(s && s !== "loaded" && s !== "complete") ||
               (f(), (n.onload = n.onreadystatechange = null))
           }),
         (i = d.getElementsByTagName(t)[0]),
         i.parentNode.insertBefore(n, i)
     })(window, document, "script", "//bat.bing.com/bat.js", "uetq")
    `
  }} data-sentry-element="Script" data-sentry-component="Bing" data-sentry-source-file="bing.tsx" />;
};
export const bingConvert = ({
  value,
  currency
}: {
  value: number;
  currency: string;
}) => {
  try {
    if (window.uetq && window.uetq) {
      window.uetq = window.uetq || [];
      window.uetq.push("event", "purchase", {
        revenue_value: value * 0.6,
        currency
      });
    }
  } catch (e) {
    console.error("uetq not loaded");
    captureException("Could not send uetq conversion");
  }
};