"use client";

import { useSearchParams } from "next/navigation";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { useTracking } from "../trackActions";
import Script from "next/script";
import { isBrowser } from "@local/utils/src/isBrowser";
export const AppLovin = () => {
  const {
    trackActionBuffered
  } = useTracking();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [axonLoaded, setAxonLoaded] = useState(false);
  const [gtagLoaded, setGtagLoaded] = useState(false);
  useEffect(() => {
    let timeoutAxon: NodeJS.Timeout | null = null;
    let timeoutGtag: NodeJS.Timeout | null = null;
    if (window.axon) {
      setAxonLoaded(true);
    } else {
      const timeoutAxon = setTimeout(() => {
        if (window.axon) {
          setAxonLoaded(true);
          clearTimeout(timeoutAxon);
        }
      }, 500);
    }
    if (window.gtag) {
      setGtagLoaded(true);
    } else {
      const timeoutGtag = setTimeout(() => {
        if (window.gtag) {
          setGtagLoaded(true);
          clearTimeout(timeoutGtag);
        }
      }, 500);
    }
    const timeout = setTimeout(() => {
      timeoutAxon && clearTimeout(timeoutAxon);
      timeoutGtag && clearTimeout(timeoutGtag);
      clearTimeout(timeout);
    }, 10000);
  }, []);
  useEffect(() => {
    const url = `${pathname}?${searchParams}`;
    if (typeof document === "undefined") {
      return;
    }

    // Applovin specific page view tracking
    const axonPageView = () => {
      trackActionBuffered("page_view", {
        "page-title": document && document?.title,
        "page-location": url
      }, {
        applovin: true
      });
    };
    if (axonLoaded && gtagLoaded) {
      axonPageView();
    }
  }, [pathname, axonLoaded, gtagLoaded]);
  return <Script id="axon-script" strategy="beforeInteractive" data-sentry-element="Script" data-sentry-component="AppLovin" data-sentry-source-file="applovin.tsx">
      {`
var AXON_EVENT_KEY="cfd4d09b-c414-4324-ac48-5cc24b26c48c";
!function(e,r){var t=["https://s.axon.ai/pixel.js","https://c.albss.com/p/l/loader.iife.js"];if(!e.axon){var a=e.axon=function(){a.performOperation?a.performOperation.apply(a,arguments):a.operationQueue.push(arguments)};a.operationQueue=[],a.ts=Date.now(),a.eventKey=AXON_EVENT_KEY;for(var n=r.getElementsByTagName("script")[0],o=0;o<t.length;o++){var i=r.createElement("script");i.async=!0,i.src=t[o],n.parentNode.insertBefore(i,n)}}}(window,document);
axon("init");
`}
    </Script>;
};
export const trackApplovin = (name: string, data?: any) => {
  if (isBrowser() && window.axon) {
    window.axon("track", name, data);
  }
};