export interface BunnyFetcherArgs {
  src: string
  width?: number
  quality?: number
}

export const getBunnyCdnUrl = ({ src, width, quality }: BunnyFetcherArgs) => {
  // Fallback in case doesn't exist
  if (!process.env.NEXT_PUBLIC_BUNNY_URL) {
    return src
  }

  const bunnyUrl = process.env.NEXT_PUBLIC_BUNNY_URL

  // Clear up the urls from PayloadCMS
  const file = src
    .replace("https://assets.stg.div.haus/", "")
    .replace("https://assets.div.haus/", "")

  const url = new URL(`https://${bunnyUrl}/${file}`)

  const params = url.searchParams

  if (width) {
    params.set("width", params.get("width") || width.toString())
  }

  if (quality) {
    params.set("quality", (quality || 50).toString())
  }

  return url.href
}
