import type { PayloadLocale } from "@local/i10n/src/types"
import { fetchApi } from "@local/utils/src/fetchApi"
import { Payload } from "./types"
import { fetchRetry } from "@local/utils/src/fetchRetry"
import { safeJSONParse } from "@local/utils"

type FetcherProps = [
  endpoint: string,
  locale?: PayloadLocale,
  querystring?: string | null,
  options?: {
    flatten?: boolean
    tags?: Array<string>
    sign?: boolean
    noCache?: boolean
  },
]

const PAYLOADCMS_ENDPOINT =
  process.env.NEXT_PUBLIC_STAGE === "stg"
    ? "https://staging.cms.div.haus"
    : "https://cms.div.haus"

export const fetchPayload = async <T>([
  endpoint,
  locale,
  querystring,
  options,
]: FetcherProps): Promise<T | null> => {
  const tags = options?.tags ? [endpoint, ...options?.tags] : [endpoint]

  try {
    const initBody = {
      method: "GET",
      next: {
        revalidate: 258000,
        tags,
        cache: "force-cache",
      },
      keepalive: true,
    } as RequestInit

    let finalEndpoint = endpoint

    if (options?.sign && process.env.HOOKDECK_SIG_SECRET) {
      initBody["headers"] = {
        "x-div-secret": process.env.HOOKDECK_SIG_SECRET,
      }
    }

    if (querystring) {
      finalEndpoint += `?${querystring.replace("?", "")}`
    }

    if (locale) {
      const firstCharacter = querystring ? "&" : "?"
      finalEndpoint += `${firstCharacter}locale=${locale}&fallback-locale=en`
    }

    const fullEndpoint = `${PAYLOADCMS_ENDPOINT}/api${finalEndpoint}`
    const req: string | null | undefined = await fetchRetry(
      fullEndpoint,
      initBody,
      3
    ).then((res) => res?.text())
    let res = safeJSONParse<T | any>(req)

    if (options?.flatten) {
      if (res?.docs && res?.docs.length > 0) {
        res = res.docs[0] as T
      } else {
        return null
      }
    }

    if (res.error) {
      const error = new Error(res.error.message)
      console.error("contents", req)
      console.error(error)
      throw error
    }

    return res as T
  } catch (error) {
    console.error("Error fetching payload", endpoint)
    console.error(error)
    return null
  }
}

export const fetchPayloadLocal = async <T>([
  endpoint,
  locale,
  querystring,
  options,
]: FetcherProps): Promise<T | null> => {
  let finalEndpoint = endpoint
  if (querystring) {
    finalEndpoint += `?${querystring.replace("?", "")}`
  }
  if (locale) {
    const firstCharacter = querystring ? "&" : "?"
    finalEndpoint += `${firstCharacter}locale=${locale}&fallback-locale=en`
  }
  const fullEndpoint = `/api/cms${finalEndpoint}`
  let res: any = await fetchApi<Payload.Query>({
    endpoint: fullEndpoint,
    method: "GET",
  })

  if (options?.flatten) {
    if (res?.docs && res?.docs.length > 0) {
      res = res.docs[0]
    } else {
      return null
    }
  }

  return res as T
}

export default fetchPayload
