"use client";

import type { Payload } from "@local/payload-client/src/types";
import { ContentBlockServer } from "./ContentBlock";
import ButtonServer, { ButtonIntent, ButtonStretch } from "./ui/button";
import HydrationContainer from "./HydrationContainer";
import dynamic from "next/dynamic";
const MuxPlayer = dynamic(() => import("@mux/mux-player-react"));
interface Props {
  block: Payload.VideoEmbedBlock;
}
export const RemoteVideoBlockServer = ({
  block
}: Props) => {
  return <ContentBlockServer className="flex flex-col items-center px-4 pt-8 pb-6 mb-4 text-gray-900 md:px-20 lg:px-32" fullWidthBgColor="bg-gray-100" data-sentry-element="ContentBlockServer" data-sentry-component="RemoteVideoBlockServer" data-sentry-source-file="VideoBlock.tsx">
      <h2 className="mb-4 text-3xl font-bold">{block.headline}</h2>
      <p className="mb-6 text-lg font-normal leading-[1.333rem]">
        {block.text}
      </p>

      <div className="relative pb-[56.25%] w-full mb-4">
        <HydrationContainer data-sentry-element="HydrationContainer" data-sentry-source-file="VideoBlock.tsx">
          {block.videoProvider && block.videoProvider === "bunny" ? <iframe src={`https://iframe.mediadelivery.net/embed/337421/${block.videoId}?autoplay=true&loop=false&muted=false&preload=true&responsive=true`} loading="lazy" className="absolute top-0 w-full h-full border-0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowFullScreen /> : <>
              <MuxPlayer className="absolute top-0 left-0 z-0 w-full h-full" defaultHiddenCaptions metadataVideoTitle={block.headline} nohotkeys playbackId={block.videoId} streamType="on-demand" maxResolution="720p" style={{
            aspectRatio: 16 / 9
          }} />
              <style>
                {`
            mux-player {
              --seek-backward-button: none;
              --seek-forward-button: none;
              --captions-button: none;
              --airplay-button: none;
              --playback-rate-button: none;
              --time-range: none;
              --time-display: none;
              --duration-display: none;
              --rendition-selectmenu: none;
            }
          `}
              </style>
            </>}
        </HydrationContainer>
      </div>

      {block.ctaContent && block.ctaUrl && <>
          <ButtonServer content={block.ctaContent} stretch={ButtonStretch.full} url={block.ctaUrl} intent={ButtonIntent.DarkCTA} className="mb-4 md:invisible md:hidden" />
          <ButtonServer content={block.ctaContent} url={block.ctaUrl} intent={ButtonIntent.DarkCTA} className="invisible hidden mb-4 md:block md:visible" />
        </>}
    </ContentBlockServer>;
};
export default RemoteVideoBlockServer;