import { isBrowser } from "@local/utils/src/isBrowser";
import Script from "next/script";
interface ClarityProps {
  clarityProjectId: string;
}
export const Clarity = ({
  clarityProjectId
}: ClarityProps) => {
  return <Script id="clarity-script" type="text/javascript" strategy="afterInteractive" dangerouslySetInnerHTML={{
    __html: `
                (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "${clarityProjectId}");
                `
  }} data-sentry-element="Script" data-sentry-component="Clarity" data-sentry-source-file="clarity.tsx" />;
};
export const trackClarity = (name: string) => {
  if (isBrowser() && window.clarity) {
    window.clarity("event", name);
  }
};