import { isBrowser } from "@local/utils/src/isBrowser";
import { captureException } from "@sentry/nextjs";
import Script from "next/script";
interface NewsBreakProps {
  newsBreakPixelId: string;
}
export const NewsBreak = ({
  newsBreakPixelId
}: NewsBreakProps) => {
  return <Script id="nbtag" data-sentry-element="Script" data-sentry-component="NewsBreak" data-sentry-source-file="newsbreak.tsx">
      {`
      !(function (e, n, t, i, p, a, s) {
        e[i] ||
          (((p = e[i] =
            function () {
              p.process ? p.process.apply(p, arguments) : p.queue.push(arguments);
            }).queue = []),
          (p.t = +new Date()),
          ((a = n.createElement(t)).async = 1),
          (a.src = 'https://static.newsbreak.com/business/tracking/nbpixel.js?t=' + 864e5 * Math.ceil(new Date() / 864e5)),
          (s = n.getElementsByTagName(t)[0]).parentNode.insertBefore(a, s));
      })(window, document, 'script', 'nbpix'),
        nbpix('init', '${newsBreakPixelId}'),
        nbpix('event', 'pageload');
        `}
    </Script>;
};
export const trackNbpix = (name: string) => {
  if (isBrowser() && window.nbpix) {
    window.nbpix("event", name);
  }
};
export const nbpixConvert = () => {
  try {
    if (window && window.nbpix) {
      window.nbpix("event", "place_an_order");
    }
  } catch (e) {
    console.error("nbpix not loaded");
    captureException("Could not send nbpix conversion");
  }
};