"use client"
import { useEffect, useRef } from "react"

const useRunOnce = (fn: () => any, sessionKey?: string) => {
  const triggered = useRef<boolean>(false)

  useEffect(() => {
    const hasBeenTriggered =
      sessionKey && sessionStorage && sessionStorage.hasOwnProperty("getItem")
        ? sessionStorage.getItem(sessionKey)
        : triggered && triggered.current

    if (!hasBeenTriggered) {
      fn()
      triggered.current = true

      if (
        sessionKey &&
        sessionStorage &&
        sessionStorage.hasOwnProperty("setItem")
      ) {
        sessionStorage.setItem(sessionKey, "true")
      }
    }
  }, [fn, sessionKey])

  return null
}

export default useRunOnce
