import type { Payload } from "./types"

interface FetcherProps {
  id: string
}

export const fetchMedia = async ({
  id,
}: FetcherProps): Promise<Payload.Media | null> => {
  let req: string | null = null

  try {
    const initBody = {
      method: "GET",
    } as RequestInit
    const PAYLOADCMS_ENDPOINT = process.env.NEXT_PUBLIC_APP_URL
    const fullEndpoint = `${PAYLOADCMS_ENDPOINT}/api/cms/media/${id}/`

    req = await fetch(fullEndpoint, initBody).then((res) => res.text())

    if (!req) {
      return null
    }
    let res = JSON.parse(req)

    if (res.error) {
      const error = new Error(res.error.message)
      console.error("contents", req)
      console.error(error)
      throw error
    }

    return res
  } catch (error) {
    console.error(error)
    return null
  }
}

interface CheckerProps {
  media: Payload.Media | string
}

export const checkMedia = async ({
  media,
}: CheckerProps): Promise<Payload.Media | null> => {
  try {
    if (typeof media === "string") {
      const req = await fetchMedia({ id: media })
      return req
    } else {
      return media
    }
  } catch (error) {
    console.error(error)
    return null
  }
}
