import { useEffect, useState } from "react";
import { useHits } from "react-instantsearch";
import { CardResults, FaqResults } from "./SearchContainer/ResultComponents";
import type { Hit } from "instantsearch.js";
import { classNames } from "@local/utils";
import ButtonServer, { ButtonIntent } from "../ui/button";
import { useTracking } from "@local/tracking";
// import type { BaseHit } from "instantsearch.js"

export const CustomHits = ({
  search_term
}: {
  search_term?: string;
}) => {
  const {
    hits,
    sendEvent
  } = useHits();
  const {
    trackActionBuffered
  } = useTracking();
  const [results, setResults] = useState({
    faqs: [] as Array<Hit>,
    products: [] as Array<Hit>,
    bundles: [] as Array<Hit>,
    categories: [] as Array<Hit>,
    other: [] as Array<Hit>
  });
  useEffect(() => {
    if (search_term && search_term.length > 1) {
      trackActionBuffered("search", {
        search_term
      });
    }
  },
  //eslint-disable-next-line
  []);
  useEffect(() => {
    const faqs: Array<Hit> = [];
    const products: Array<Hit> = [];
    const bundles: Array<Hit> = [];
    const categories: Array<Hit> = [];
    const other: Array<Hit> = [];
    hits.map(hit => {
      switch (hit.type) {
        case "product":
          products.push(hit);
          break;
        case "bundle":
          bundles.push(hit);
          break;
        case "category":
          categories.push(hit);
          break;
        case "faq":
          faqs.push(hit);
          break;
        default:
          other.push(hit);
          break;
      }
    });
    setResults({
      faqs,
      products,
      bundles,
      categories,
      other
    });
  }, [hits]);
  const ResultRow = ({
    title,
    searchResults,
    type
  }: {
    title: string;
    searchResults: Hit[];
    type: "products" | "categories" | "bundles" | "faqs";
  }) => <div className={classNames("w-full px-6 pt-6 pb-1 md:px-8 lg:px-12 md:pt-10", type === "faqs" ? "bg-neutral-100" : "")} data-sentry-component="ResultRow" data-sentry-source-file="Hits.tsx">
      <h2 className="pb-2 my-3 text-lg border-b-[1px] border-b-neutral-500">
        <b>{title}</b> ({searchResults.length})
      </h2>
      {type === "faqs" ? <FaqResults faqs={results.faqs} sendEvent={sendEvent} /> : <CardResults cards={searchResults} sendEvent={sendEvent} type={type} />}
    </div>;
  return <div className="pb-28" data-sentry-component="CustomHits" data-sentry-source-file="Hits.tsx">
      {results.faqs.length > 0 && <ResultRow title="Frequently Asked Questions" searchResults={results.faqs} type="faqs" />}

      {results.products.length > 0 && <>
          <ResultRow title="Products" searchResults={results.products} type="products" />
          <div className="flex justify-center w-full px-6 md:px-8 lg:px-12">
            <ButtonServer content="View All Products" url="/category/all-products/" intent={ButtonIntent.DarkCTA} />
          </div>
        </>}

      {results.categories.length > 0 && <>
          <ResultRow title="Categories" searchResults={results.categories} type="categories" />
          <div className="flex justify-center w-full px-6 md:px-8 lg:px-12">
            <ButtonServer content="View All Categories" url="/categories" intent={ButtonIntent.DarkCTA} />
          </div>
        </>}

      {results.bundles.length > 0 && <>
          <ResultRow title="Bundles" searchResults={results.bundles} type="bundles" />
          <div className="flex justify-center w-full px-6 md:px-8 lg:px-12v">
            <ButtonServer content="View All Bundles" url="/category/bundles/" intent={ButtonIntent.DarkCTA} />
          </div>
        </>}
    </div>;
};