"use client"
import { useState, useEffect } from "react"
import { storageAvailable } from "./storageAvailable"

export const useLocalStorage = <T>(
  key: string,
  initialValue: T
): {
  storedValue: T
  setValue: (value: T) => void
  getValue: () => T | undefined
  removeValue: () => void
} => {
  const [storedValue, setStoredValue] = useState<T>(initialValue)

  // Needed to avoid hydration mismatch
  useEffect(() => {
    const storage = storageAvailable()
    try {
      const item = storage ? window.localStorage.getItem(key) : null
      // Parse JSON if possible, else return as string
      let valueToStore
      try {
        valueToStore = JSON.parse(item as string) || initialValue
      } catch (e) {
        valueToStore = item || initialValue
      }
      setStoredValue(valueToStore)
    } catch (error) {
      console.error(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key])

  const setValue = (value: T) => {
    const storage = storageAvailable()
    try {
      setStoredValue(value)
      // Stringify JSON if possible, else store as string
      let valueToStore
      try {
        valueToStore = JSON.stringify(value)
      } catch (e) {
        valueToStore = value as unknown as string
      }
      if (storage) {
        window.localStorage.setItem(key, valueToStore)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getValue = () => {
    const storage = storageAvailable()
    if (storage) {
      window.localStorage.getItem(key)
    }
    return undefined
  }

  const removeValue = () => {
    const storage = storageAvailable()
    if (storage) {
      window.localStorage.removeItem(key)
    }
  }

  return { storedValue, setValue, getValue, removeValue }
}
