"use client";

import { sendBacon } from "@local/utils/src/sendBeacon";
import { objectToShiftedBase64 } from "@local/utils/src/safeBase64";
import { baseParametersForTracking, trackAction } from "./trackActions";
import { getTrackingCookies } from "./getTrackingCookies";
import { identifyPostHog } from "./scripts/posthog";

// https://developers.google.com/tag-platform/tag-manager/web/datalayer
// https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#data-layer
interface IdentifyUserArgs {
  email?: string | null;
  specific?: "exp" | "nonexp";
  phone?: string | null;
}
export const identifyUser = ({
  email,
  phone,
  specific
}: IdentifyUserArgs) => {
  try {
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const PhoneRegex = "^\\+[1-9]\\d{1,14}$";
    let emailClean = null;
    let phoneClean = null;
    const trackingStuff = getTrackingCookies();
    if (email) {
      emailClean = email.toLowerCase().trim();
      if (!emailClean.match(emailRegex)) {
        console.error("Invalid email");
        emailClean = null;
      }
    }
    if (phone) {
      phoneClean = phone.trim();
      if (!phoneClean.match(PhoneRegex)) {
        console.error("Invalid phone");
        phoneClean = null;
      }
    }
    if (emailClean) {
      trackAction("consent", {
        action: "accept",
        category: "promotional",
        valid_until: "unlimited",
        identification_type: "cookie",
        email: emailClean,
        identification: emailClean
      });
      if (window.exponea && window.exponea.track && (specific === "exp" || !specific)) {
        window.exponea.identify({
          email_ID: emailClean
        });
      }
      // ref: https://support.google.com/google-ads/answer/13258081?#Add_a_code_snippet&zippy=identify-and-define-your-enhanced-conversions-fields
      if (window.gtag) {
        window.gtag("set", "user_data", {
          email: emailClean
        });
      }
    }
    if (phoneClean) {
      trackAction("consent", {
        action: "accept",
        category: "promo-sms",
        identification_type: "application",
        identification: phone,
        valid_until: "unlimited"
      });
      if (window.exponea && (specific === "exp" || !specific)) {
        window.exponea.identify({
          customer_phone: phone
        });
      }
      // ref: https://support.google.com/google-ads/answer/13258081?#Add_a_code_snippet&zippy=identify-and-define-your-enhanced-conversions-fields
      if (window.gtag) {
        window.gtag("set", "user_data", {
          phone_number: phoneClean
        });
      }
    }
    if (emailClean || phoneClean) {
      identifyPostHog({
        email: emailClean,
        phone: phoneClean
      });
    }
    const endpointDomain = `${process.env.NEXT_PUBLIC_APP_URL}/api`;
    sendEvent: {
      if (!specific || specific === "nonexp") {
        const endpoint = `${endpointDomain}/t/`;
        const body = {
          channelId: window.channel ?? process.env.NEXT_PUBLIC_CHANNEL_ID as string,
          data: {
            email: emailClean,
            phone: phoneClean
          },
          name: "identify",
          user: baseParametersForTracking()
        };
        const encodedBody = objectToShiftedBase64(body, 24);
        const blob = new Blob([encodedBody], {
          type: "text/plain"
        });
        sendBacon(endpoint, blob);
      }
    }
    identifyFlow: {
      const url = new URL(window.location.href);
      const params = url.searchParams;
      const endpoint = `${endpointDomain}/i/`;
      const body = {
        c: trackingStuff.chills,
        email: emailClean,
        l: url.href,
        phone: phoneClean,
        r: document && document.referrer ? document.referrer : null,
        rc: params.has("r_sub_id") ? params.get("r_sub_id") : null,
        v: trackingStuff.vibes
      };
      const encodedBody = objectToShiftedBase64(body, 24);
      const blob = new Blob([encodedBody], {
        type: "text/plain"
      });
      sendBacon(endpoint, blob);
    }
  } catch (e) {
    console.error("ttq not loaded");
  }
};