import Script from "next/script";
import { getTrackingCookies } from "../getTrackingCookies";
import { isBrowser } from "@local/utils/src/isBrowser";
export const Posthog = () => {
  const {
    gtmUpi
  } = getTrackingCookies();
  return <Script id="init-posthog" data-sentry-element="Script" data-sentry-component="Posthog" data-sentry-source-file="posthog.tsx">
      {`
!function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys onSessionId".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
posthog.init('phc_CoU6p1XZv62l9TmFXWRSYpcs0p060G2gyTfOjpF6JzX',{api_host: "https://ph.baerskintactical.com",ui_host: 'https://us.posthog.com'});
window.posthog.identify("${gtmUpi}")
`}
    </Script>;
};
export const trackPostHog = (name: string, data?: any) => {
  if (isBrowser() && window.posthog) {
    window.posthog.capture(name, data);
  }
};
export const identifyPostHog = ({
  email,
  phone
}: {
  email?: string | null;
  phone?: string | null;
}) => {
  if (isBrowser() && window.posthog) {
    const trackingStuff = getTrackingCookies();
    const gtmUpi = trackingStuff.gtmUpi;
    window.posthog.identify(gtmUpi, {
      email: email ?? undefined,
      phone: phone ?? undefined
    });
  }
};