import { captureException } from "@sentry/nextjs";
import Script from "next/script";
import type { ConversionProps } from "./types";
interface GoogleProps {
  googleId: string;
  gadsId?: string | null;
}
export const Google = ({
  googleId,
  gadsId
}: GoogleProps) => {
  return <>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${googleId}`} data-sentry-element="Script" data-sentry-source-file="google.tsx" />
      <Script id="google-analytics" strategy="afterInteractive" dangerouslySetInnerHTML={{
      __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${googleId}');
                  ${gadsId ? `gtag('config', '${gadsId}');` : ""}
                `
    }} data-sentry-element="Script" data-sentry-source-file="google.tsx" />
    </>;
};
export const gadsConvert = ({
  value,
  currency,
  id
}: ConversionProps) => {
  try {
    if (window && window.gtag) {
      window.gtag("event", "conversion", {
        send_to: `${process.env.NEXT_PUBLIC_GADS_ID}/${process.env.NEXT_PUBLIC_GADS_CONVERSION}`,
        value: Math.round(value * 0.6).toString(),
        currency: currency,
        transaction_id: id
      });
    } else {
      throw new Error("gtag not loaded");
    }
  } catch (e) {
    console.error("gtag not loaded");
    captureException("Could not send gads conversion");
  }
};